var Onfido=require('onfido-sdk-ui');function getSDKToken(applicantId){// request to localhost:3000 sdk_token endpoint (hits sdk_token action in onfido_controller.rb)
var url="onfido/sdk_token?applicant_id=".concat(applicantId);var request=new XMLHttpRequest();var token="awaiting real token";var authenticity_token=document.querySelector("meta[name=csrf-token]").content;request.open("POST",url,false);request.setRequestHeader("X-CSRF-Token",authenticity_token);request.onload=function(){if(request.status>=200&&request.status<400){var response=JSON.parse(request.responseText);token=response.token;}};request.send({applicant_id:applicantId});return token;}function onfidoSteps(){return[{type:"welcome",options:{title:"Let's Verify your Identity",descriptions:["You will need either your driving licence, passport or identity document available to complete this step."],nextButton:"Verify my Identity"}},{type:"document",options:{documentTypes:{passport:true,driving_licence:true,national_identity_card:true,residence_permit:false},showCountrySelection:false}},"face","complete"];}function toggleButton(button){if(button.disabled){button.disabled=false;}else{button.disabled=true;}}function onfidoOther(sdkToken,continueBtn,onfidoFileUploadEnabled){var onfidoFileUploadEnabledBool=String(onfidoFileUploadEnabled).toLowerCase()==='true';var forceCrossDevice=!onfidoFileUploadEnabledBool;var onfido=Onfido.init({useModal:false,token:sdkToken,disableAnalytics:true,forceCrossDevice:forceCrossDevice,useLiveDocumentCapture:true,uploadFallback:false,onComplete:function onComplete(completeData){continueBtn&&toggleButton(continueBtn);continueBtn.form.submit();},steps:onfidoSteps()});}function onfidoMobile(sdkToken,continueBtn,onfidoFileUploadEnabled){var onfidoFileUploadEnabledBool=String(onfidoFileUploadEnabled).toLowerCase()==='true';var forceCrossDevice=!onfidoFileUploadEnabledBool;var navbar=document.querySelector(".navbar");navbar.classList.remove("navbar-to-back");// clean slate
navbar.classList.add("navbar-to-back");var onfido=Onfido.init({useModal:true,isModalOpen:true,disableAnalytics:true,forceCrossDevice:forceCrossDevice,useLiveDocumentCapture:true,uploadFallback:false,onModalRequestClose:function onModalRequestClose(){// workaround for floating button that we don't want there in the first place and can't get rid of
var closeBtnAfterInit=document.querySelector(".onfido-sdk-ui-Modal-closeButton");closeBtnAfterInit.remove();},token:sdkToken,onComplete:function onComplete(completeData){var instruction=document.querySelector("[data-display='identity-verification-complete']");instruction.classList.remove("d-none");continueBtn&&toggleButton(continueBtn);continueBtn.form.submit();onfido.setOptions({isModalOpen:false});navbar.classList.remove("navbar-to-back");},steps:onfidoSteps()});// Commented the process of adding onfido-overwrite class to onfido-sdk-ui-Modal-portal.
// The css properties of the onfido-overwrite have been directly added to the class .onfido-sdk-ui-Theme-modalOverlay
// By doing so, we set the modal to be below the navigation header and a little above the bottom of the page
// const sdk = document.querySelector(".onfido-sdk-ui-Modal-portal");
// sdk.classList.add("onfido-overwrite");
}function onfidoIdv(){var onFidoContainer=document.querySelector("[data-applicant-id]");if(!onFidoContainer)return;var applicantId=onFidoContainer.dataset["applicantId"];var onfidoFileUploadEnabled=onFidoContainer.dataset["onfidoFileUploadEnabled"];var sdkToken=getSDKToken(applicantId);// JS toggle button workaround for iOS
var continueBtn=document.querySelector("[data-display='idv-continue-btn']");continueBtn&&toggleButton(continueBtn);// display desktop/tablet (no modal) or mobile (use modal) version of sdk
return window.innerWidth>480?onfidoOther(sdkToken,continueBtn,onfidoFileUploadEnabled):onfidoMobile(sdkToken,continueBtn,onfidoFileUploadEnabled);}export default onfidoIdv;